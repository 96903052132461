import React from 'react';
import Mailer from '../components/Contact/Mailer';


const Contact = () => {
  return (
    <div
      // style={{
      //   display: 'flex',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   height: '90vh'
      // }}
    >
      
      <Mailer />
      
    </div>
  );
};

export default Contact;