import React from 'react';
import {ContentContainer, Description, Content, Space} from './aboutElements';

const About = () => {
  return (
    <div>
      <ContentContainer>
        <Description>
        <Content>Jonathan Root is an English Portrait Photographer.<br /><br />His subjects include: David Hockney, Peter Blake, Allen Jones, Gilbert & George, Philippe Starck, Marc Newson, Richard Rogers, Norman Foster, Zaha Hadid, Alan Bennett, Mark Rylance, Lord Bath, and Nicholas Haslam.<br /><br />His portraits of Gavin Turk, Craigie Aitchison, David Adjaye, John Winter, Lord Harewood and Lord March are part of The National Portrait Gallery Photographs Collection.<br /><br />Exhibitions: “The John Kobal Photographic Portrait Awards” 1995, 1996 and 2000. “The Edwardian Drape Society” (Tapestry Gallery) 2004. “Lords and Ladies” (Tapestry Gallery) 2004. “Sitting Pretty” (Rabih Hage Gallery) 2007.</Content>
        </Description>
        <Space>
        </Space>

        <Description>
        {/* <img src='/images/Photographer-Jonathan-Root-retro-interiors-8-950x1425.jpeg' />
        <p>Jonathan Root - Chalk Farm, London</p> */}
        </Description>
        

      </ContentContainer>
      
      
      

    </div>
  );
};

export default About;