import React from 'react'
import SliderCarousel from '../components/Slider/SliderCarousel'
import 'bootstrap/dist/css/bootstrap.min.css';
//import { ImageContainer } from '../components/ImagePage/PageElements';

const Main = () => {
  return (
    <div 
      // style={{
      //   display: 'flex',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   height: '90vh'
      // }}
      >
        
      <SliderCarousel/>
      
      {/* <h1>Lol</h1> */}
      </div>
  )
}

export default Main